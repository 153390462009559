.tabs {
  margin-bottom: 20px;
  .tab-list {
    display: none;
    margin: 0 0 2px;
    list-style: none;
    &[role='tablist'] {
      display: block;
    }
    li {
      position: relative;
      display: inline-block;
      margin: 0 25px 0 0;
      padding: 0;
      border-radius: 10px 10px 0 0;
      background: palette(elements, tab);
      white-space: nowrap;
      &.active {
        background: palette(elements, tab-selected);
        &:after {
          border-bottom-color: palette(elements, tab-selected);
        }
      }
      &:hover,
      &:active {
        background: palette(elements, tab-hover);
        &:after {
          border-bottom-color: palette(elements, tab-hover);
        }
      }
      &:before {
        content: none;
      }
      &:after {
        position: absolute;
        right: -24px;
        bottom: 0;
        display: block;
        width: 0;
        border-right: 25px solid transparent;
        border-bottom: 33px solid palette(elements, tab);
        content: '';
      }
      a {
        display: block;
        padding: 10px 15px;
        color: palette(elements, tab-txt);
        font-weight: 600;
        line-height: 1.3em;
        &:hover,
        &:active {
          color: palette(elements, tab-hover-txt);
        }
        &[role='tab']:focus {
          text-decoration: none;
        }
        &[aria-selected] {
          color: palette(elements, tab-selected-txt);
          &:hover {
            color: palette(elements, tab-hover-txt);
          }
        }
      }
    }
  }
  [role='tabpanel'] {
    padding: 20px;
    border-top: 2px solid palette(elements, tab);
    border-bottom: 2px solid palette(elements, tab);
    background: palette(elements, tab-content);
    &[aria-hidden='true'] {
      display: none;
    }
    .tab-title {
      display: none;
    }
  }
}
