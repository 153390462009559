.accordions {
  margin-bottom: 20px;
  .accordion-title {
    position: relative;
    margin: 20px 0 0;
    padding: 0 45px 0 0;
    height: 45px;
    border-bottom: 3px solid palette(elements, acc-border);
    color: palette(elements, acc-text);

    @include font-size(15, 17);
    &:hover {
      border-bottom: 3px solid palette(elements, acc-border-hover);
      color: palette(elements, acc-text-hover);
      cursor: pointer;
      span {
        border-left: 1px solid palette(elements, acc-border-hover);
        &:after {
          color: palette(elements, acc-text);
        }
      }
    }
    &:first-of-type {
      margin-top: 0;
    }
    &.is-active {
      margin-bottom: 10px;
      border-bottom: 3px solid palette(elements, acc-border-open);
      button {
        color: palette(elements, acc-text-open);
      }
      span {
        border-left: 1px solid palette(elements, acc-border-open);
        &:after {
          color: palette(elements, acc-text-open);
          content: '\f077';
        }
      }
    }
    button {
      position: absolute;
      top: 0;
      display: block;
      margin: 0;
      max-width: none;
      width: 100%;
      height: 100%;
      border: 0;
      border-radius: 0;
      background: transparent;
      color: palette(elements, acc-text);
      text-align: left;
      &:focus,
      &:hover {
        outline: 0;
        border-radius: 0;
        color: palette(elements, acc-text-hover);
      }
    }
    span {
      position: absolute;
      top: 0;
      right: 0;
      width: 45px;
      height: 100%;
      border-left: 1px solid palette(elements, acc-border-hover);
      &:after {
        position: absolute;
        top: 50%;
        left: 14px;
        width: 15px;
        height: 17px;
        color: palette(elements, acc-text);
        content: '\f078';
        text-align: center;
        text-decoration: inherit;
        font-weight: normal;
        font-style: normal;
        font-family: FontAwesome;
        transform: translateY(-8px);
      }
    }
  }
  .accordion-title + [aria-hidden] {
    display: none;
  }
  .accordion-title + [aria-hidden='false'] {
    display: block;
  }
  .accordion-content {
    margin-top: -10px;
    padding: 20px;
    border-bottom: 1px solid #afafaf;
    background: #ffffff;
    color: black;
    font-weight: 300;
  }
}
