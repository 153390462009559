/*!
* WS Starter
* Designed by LRS Web Solutions (http://lrswebsolutions.com)
* Visit http://lrswebsolutions.com
*/


// Presets & Variables
@import 'presets/variables';
@import 'presets/mixins';

// Layout
@import 'layout/sidebar';

// Components
@import 'components/images';
@import 'components/forms';
@import 'components/accordions';
@import 'components/tabs';
@import 'components/quiz';

// Vendor

// Extras
@import 'components/shame';
