// PX TO REM FUNCTION
@use "sass:math";

@function strip-unit($num) {
  @return math.div($num, $num * 0 + 1);
}

@function rem-calc($values, $base: $base-font-size) {
  $rem-values: (
  );
$count: length($values);

// If no base is defined, defer to the global font size
@if $base==null {
  $base: 100%;
}

// If the base font size is a %, then multiply it by 16px
// This is because 100% font size = 16px in most all browsers
@if unit($base)=='%' {
  $base: 
    math.div($base, 100%) * 16px;
}

@if $count==1 {
  @return -ws-to-rem($values, $base
);
}

@for $i from 1 through $count {
  $rem-values: append($rem-values, -ws-to-rem(nth($values, $i), $base));
}

@return $rem-values;
}

@function -ws-to-rem($value, $base: null) {

  // Check if the value is a number
  @if type-of($value) !='number' {
    @warn inspect($value)+' was passed to rem-calc(), which is not a number.';
    @return $value;
  }

  // Calculate rem if units for $value is not rem
  @if unit($value) !='rem' {
    $value: math.div(strip-unit($value), strip-unit($base)) * 1rem;
  }

  // Turn 0rem into 0
  @if $value==0rem {
    $value: 0;
  }

  @return $value;
}

// BASE TRANSITION MIXIN
@mixin transition($property: all, $duration: 0.2s, $ease: ease) {
  transition: $property $duration $ease;
}

// usage @include transition(all, 0.2s, ease);
// RESPONSIVE MEDIA QUERIES
@mixin respond-to($min: 0, $max: 0, $prop: 'width', $media: 'screen') {
  $query: '';

  @if $min !=0 and $max !=0 {
    $query: '(min-#{$prop}: #{$min}) and (max-#{$prop}: #{$max})';
  }

  @else if $min !=0 and $max==0 {
    $query: '(min-#{$prop}: #{$min})';
  }

  @else if $min==0 and $max !=0 {
    $query: '(max-#{$prop}: #{$max})';
  }

  @media only #{$media} and #{$query} {
    @content;
  }

  ;
}

@mixin mq($query) {
  @if length($query)==1 {
    $query1: nth($query, 1);

    @media screen and (min-width: $query1) {
      @content
    }
  }

  @else if length($query)==2 {
    $query1: nth($query, 1);
    $query2: nth($query, 2);

    @if $query2=='portrait' {
      @media screen and (min-width: $query1 + px) and (orientation: portrait) {
        @content
      }
    }

    @else if $query2=='landscape' {
      @media screen and (min-width: $query1 + px) and (orientation: landscape) {
        @content
      }
    }

    @else {
      @media screen and (min-width: $query1 + px) and (max-width: $query2 + px) {
        @content
      }
    }
  }

  @else if length($query)==3 {
    $query1: nth($query, 1);
    $query2: nth($query, 2);
    $query3: nth($query, 3);

    @if $query3=='portrait' {
      @media screen and (min-width: $query1 + px) and (max-width: $query2 - 1 + px) and (orientation: portrait) {
        @content
      }
    }

    @else if $query3=='landscape' {
      @media screen and (min-width: $query1 + px) and (max-width: $query2 - 1 + px) and (orientation: landscape) {
        @content
      }
    }

    @else {
      @media screen and (min-width: $query1 + px) and (max-width: $query2 + px) {
        @content
      }
    }
  }
}

// usage @include respond-to($bp-large) {...} or @include respond-to($max: $bp-large) {...} or @include respond-to(1100px, $prop: height} or @include respond-to($media: 'print') {...}
// CONVERT PX FONT AND LINE HEIGHTS TO REMS
@mixin font-size($sizeValue: 1, $line: $sizeValue * 1.5) {
  font-size: math.div($sizeValue, 16) + rem;
  line-height: math.div($line, 16) + rem;
}

// usage @include font-size(12, 16);