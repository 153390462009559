/*!
* WS Starter
* Designed by LRS Web Solutions (http://lrswebsolutions.com)
* Visit http://lrswebsolutions.com
*/
.validation-errors {
  font-weight: bold;
  color: #D8000C;
  background-color: #FFBABA;
  padding: 15px;
  border: 1px solid #D8000C;
}

.accordions {
  margin-bottom: 20px;
}
.accordions .accordion-title {
  position: relative;
  margin: 20px 0 0;
  padding: 0 45px 0 0;
  height: 45px;
  border-bottom: 3px solid #afafaf;
  color: #001b4d;
  font-size: 0.9375rem;
  line-height: 1.0625rem;
}
.accordions .accordion-title:hover {
  border-bottom: 3px solid #1f92cc;
  color: #000000;
  cursor: pointer;
}
.accordions .accordion-title:hover span {
  border-left: 1px solid #1f92cc;
}
.accordions .accordion-title:hover span:after {
  color: #001b4d;
}
.accordions .accordion-title:first-of-type {
  margin-top: 0;
}
.accordions .accordion-title.is-active {
  margin-bottom: 10px;
  border-bottom: 3px solid #db8a05;
}
.accordions .accordion-title.is-active button {
  color: #002f87;
}
.accordions .accordion-title.is-active span {
  border-left: 1px solid #db8a05;
}
.accordions .accordion-title.is-active span:after {
  color: #002f87;
  content: "\f077";
}
.accordions .accordion-title button {
  position: absolute;
  top: 0;
  display: block;
  margin: 0;
  max-width: none;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 0;
  background: transparent;
  color: #001b4d;
  text-align: left;
}
.accordions .accordion-title button:focus, .accordions .accordion-title button:hover {
  outline: 0;
  border-radius: 0;
  color: #000000;
}
.accordions .accordion-title span {
  position: absolute;
  top: 0;
  right: 0;
  width: 45px;
  height: 100%;
  border-left: 1px solid #1f92cc;
}
.accordions .accordion-title span:after {
  position: absolute;
  top: 50%;
  left: 14px;
  width: 15px;
  height: 17px;
  color: #001b4d;
  content: "\f078";
  text-align: center;
  text-decoration: inherit;
  font-weight: normal;
  font-style: normal;
  font-family: FontAwesome;
  transform: translateY(-8px);
}
.accordions .accordion-title + [aria-hidden] {
  display: none;
}
.accordions .accordion-title + [aria-hidden=false] {
  display: block;
}
.accordions .accordion-content {
  margin-top: -10px;
  padding: 20px;
  border-bottom: 1px solid #afafaf;
  background: #ffffff;
  color: black;
  font-weight: 300;
}

.tabs {
  margin-bottom: 20px;
}
.tabs .tab-list {
  display: none;
  margin: 0 0 2px;
  list-style: none;
}
.tabs .tab-list[role=tablist] {
  display: block;
}
.tabs .tab-list li {
  position: relative;
  display: inline-block;
  margin: 0 25px 0 0;
  padding: 0;
  border-radius: 10px 10px 0 0;
  background: #f2a900;
  white-space: nowrap;
}
.tabs .tab-list li.active {
  background: #001b4c;
}
.tabs .tab-list li.active:after {
  border-bottom-color: #001b4c;
}
.tabs .tab-list li:hover, .tabs .tab-list li:active {
  background: #1f92cc;
}
.tabs .tab-list li:hover:after, .tabs .tab-list li:active:after {
  border-bottom-color: #1f92cc;
}
.tabs .tab-list li:before {
  content: none;
}
.tabs .tab-list li:after {
  position: absolute;
  right: -24px;
  bottom: 0;
  display: block;
  width: 0;
  border-right: 25px solid transparent;
  border-bottom: 33px solid #f2a900;
  content: "";
}
.tabs .tab-list li a {
  display: block;
  padding: 10px 15px;
  color: #001b4d;
  font-weight: 600;
  line-height: 1.3em;
}
.tabs .tab-list li a:hover, .tabs .tab-list li a:active {
  color: #001b4d;
}
.tabs .tab-list li a[role=tab]:focus {
  text-decoration: none;
}
.tabs .tab-list li a[aria-selected] {
  color: #cccccc;
}
.tabs .tab-list li a[aria-selected]:hover {
  color: #001b4d;
}
.tabs [role=tabpanel] {
  padding: 20px;
  border-top: 2px solid #f2a900;
  border-bottom: 2px solid #f2a900;
  background: #f2f4f9;
}
.tabs [role=tabpanel][aria-hidden=true] {
  display: none;
}
.tabs [role=tabpanel] .tab-title {
  display: none;
}

.quiz {
  border: 1px solid gray;
}
.quiz .question {
  display: none;
}
.quiz .question:first-child {
  display: block;
}
.quiz .results {
  display: none;
}
.quiz .results .high-risk,
.quiz .results .low-risk,
.quiz .results .no-risk {
  display: none;
}